import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import { useSourceParams } from 'hooks/useSourceParams';
import img from 'img/blog/5-ux-research-methods-for-the-remote-times/cover.png';

import img1 from 'img/blog/5-ux-research-methods-for-the-remote-times/lsrecord.png';
import img2 from 'img/blog/5-ux-research-methods-for-the-remote-times/nps.png';
import img3 from 'img/blog/5-ux-research-methods-for-the-remote-times/moscow.png';

const Content = () => {
  const bannerLink = useSourceParams('banner');
  return (
    <Post>
      <p>
        Wondering how you can keep using your user research methods during the pandemic times? It’s
        much easier than you think! Here’s a guide to adapting your UX methods to the remote times.
      </p>
      <p>
        Before the global pandemic hit us, you had the option of conducting user experience research
        either remotely or in-person. While the participants were performing tasks in a lab, you
        could be watching how they behave and what obstacles they might encounter.
      </p>
      <p>
        It seems like those times are not returning anytime soon. Nowadays, when social distancing
        is crucial and avoiding gatherings is recommended, in-person research is simply out of the
        question. Telling when (or if) it will be back is virtually impossible. No matter what’s
        your industry or company size, adapting to the new, remote world is crucial. This also
        applies to adapting your UX research methods.
      </p>
      <p>
        Fortunately, remote user-search methods aren’t anything new. Companies have been conducting
        remote user research for years, even if it was on a small scale. Even companies who didn’t
        use remote UX research methods before can quickly adapt to using those methods of testing.
        Below we’re listing the main advantages to convince you:
      </p>
      <h2>Benefits of remote UX testing</h2>
      <ul>
        <li>
          <strong>Inexpensive</strong> – The main issue with in-person UX testing lies in the costs.
          With remote research, you don’t have to pay for a fancy lab or travelling. As long as both
          you and the participants have a laptop (or even a smartphone) and WiFi, you don’t need
          much more. That makes remote UX research cheap enough even for small companies, or for
          those with a tight budget.
        </li>

        <li>
          <strong>Easier to organize </strong>– Organizing an in-person UX testing session does
          require a good deal of logistics: agreeing the date and time, figuring out travel plans,
          booking a room for testing, purchasing additional equipment, and much more. When it comes
          to{' '}
          <a
            href="https://biz30.timedoctor.com/remote-meeting/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            remote sessions
          </a>
          , you don’t have to worry about travel costs or booking lab facilities. Scheduling
          meetings is also easier with dedicated tools, such as{' '}
          <a href="https://harmonizely.com/" target="_blank" rel="noopener noreferrer nofollow">
            Harmonizely
          </a>
          .
        </li>

        <li>
          <strong>More research participants</strong> - Getting people from other cities to join
          your stationary research would be complicated enough, not to mention if they’re all from
          different countries. When it comes to remote tests, anyone can join as long as they have a
          stable internet connection. Remote interview or testing is also far more convenient for
          those with limited mobility, as well as people with small children. In other words, it’s
          more inclusive.{' '}
        </li>

        <li>
          <strong>Convenient for the participants </strong>– Not only the participants don’t need to
          travel, but they can also use their own devices. They are more relaxed at home than in the
          lab or in your office. What’s more, you’ll also get data from a range or different devices
          so you can make sure your product works well on all of them.
        </li>
      </ul>
      <h2>Qualitative and quantitative methods</h2>
      <p>As a rule of thumb, we can divide UX research methods into two types:</p>
      <ul>
        <li>
          <p>
            <strong>Qualitative</strong> –{' '}
            <a
              href="/blog/guide-to-qualitative-ux-research/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Qualitative methods
            </a>{' '}
            can tell you more about your participant and their behaviour in a given situation. This
            way, you can find out what they think and how they feel about your product or service,
            how they use your product and what kind of comments they might have. Examples of
            qualitative research methods include session recordings, questionnaires with open-ended
            questions, and interviews.{' '}
          </p>
          <img src={img1} alt="LiveSession recording" title="Snapshot from LiveSession App" />
        </li>

        <li>
          <p>
            <strong>Quantitative</strong> – This type of research provides you with numerical data.
            It can be used to find out how many times a specific link was clicked, how many people
            have completed a particular action, or how many times a product was bought. The most
            prominent examples of quantitative research include A/B tests and questionnaires with
            close-ended questions, such as the Net Promoter Score.
          </p>
          <img src={img2} alt="Net Promoter Score" title="NPS" />
          <p>
            Source:{' '}
            <a
              href="https://www.surveymonkey.com/mp/net-promoter-score/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              SurveyMonkey
            </a>
          </p>
        </li>
      </ul>
      <p>
        So, which approach should you use? This depends on what kind of data you need. If you’d like
        to find out “how much” or “how many”, then the quantitative approach will be helpful. When
        you’d like to see what kind of problems your users have, or why a specific thing is
        happening, qualitative methods are what you need.
      </p>
      <p>
        You can also combine qualitative methods with quantitative ones. For example, you might have
        seen a “How would you rate your experience” survey with a field to add your own comment.
        This is an example of a research method that gives you both a numerical value and an insight
        on what the customer thinks about the interaction as a whole.
      </p>
      <h2>UX research methods to use remotely</h2>
      <p>
        Once you know what kind of data you need for your business, it’s time to choose the UX
        research methods that would work best in your case. Here’s an overview of some of the most
        popular ones you can easily use remotely.
      </p>
      <h3>Think-aloud protocol</h3>
      <p>
        It’s a popular usability testing method that also happens to be remote-friendly. In this
        case, your participants can either use a released product or an interactive prototype.
        They’re asked to describe their actions as they go, just like they’re thinking out loud.
        This method is sure to bring insights if your users have issues with a specific task. If you
        need to test your MVP, it will also come in handy.
      </p>
      <h3>MoSCoW method </h3>
      <p>
        When you think about remote user research, surveys and questionnaires are probably the first
        things that come to mind. For instance, you can ask users about the features they’d like to
        see, and use a dedicated framework to prioritize them. The <strong>MoSCoW method</strong> is
        really simple and inexpensive. You present a list of features to the user and ask them
        whether a particular solution is a <em>must have, nice to have, could have</em>, or if they
        don’t care about it at all (<em>won’t have</em>).
      </p>
      <img src={img3} alt="MoSCow Prioritization" title="MoSCoW method" />
      <h3>A/B tests</h3>
      <p>
        An affordable research method that doesn’t even require any involvement from the user! A/B
        testing is a type of experiment when one part of the audience sees one version of the
        website, and the second part sees another. You can then compare the results for both
        variants and see which one is more effective. It’s an easy way to track how a single
        variable impacts the user experience.
      </p>
      <p>
        Want to read a bit more about A/B testing? You’ll enjoy our{' '}
        <a
          href="/tutorials/guide-to-ab-testing-with-livesession/"
          target="_blank"
          rel="noopener noreferrer"
        >
          handy A/B testing guide
        </a>
        .
      </p>
      <h3>In-depth interviews (IDI)</h3>
      <p>
        IDI is a purely qualitative method. In this case, you ask a single user about their needs,
        expectations, or issues they’ve experienced while using a service or an app. It’s a great
        way to get in-depth insights and step in the user’s shoes. What’s more, it might also be a
        source of surprising findings you wouldn’t get to with quantitative methods.
      </p>
      <h3>Card sorting</h3>
      <p>
        Card sorting is about asking participants to sort topics or ideas into either already given
        categories (closed card sorting) or ones they created themselves (open card sorting). This
        makes it easier for you to understand how the users assign content into categories, and how
        you can make navigation more intuitive.
      </p>
      <h2>
        Useful t<strong>ools for remote research</strong>
      </h2>
      <p>
        <strong> </strong>
      </p>
      <p>
        Found a method to suit your needs? Great! Now it’s time to focus on choosing the right tools
        for your remote study. We’re well aware that the number of UX tools available on the market
        right now is quite large. If you’re not quite sure what you need, you might get easily
        confused. We’re here to help you out:
      </p>
      <h3>Video communication tools</h3>
      <p>
        If you’re working remotely, it’s pretty likely you’re using a video{' '}
        <a
          href="https://www.smarttask.io/blog/communication-tools"
          target="_blank"
          rel="noopener noreferrer"
        >
          communication tool
        </a>{' '}
        daily. They’re essential for remote research, too. When you decide on one, make sure it
        allows you to record the session so you can analyze it later.
      </p>
      <p>
        <strong>Our choice for remote UX research:</strong>{' '}
        <a href="https://zoom.us/" target="_blank" rel="noopener noreferrer nofollow">
          Zoom
        </a>
      </p>
      <h3>Session recording tools</h3>
      <p>
        These tools are a brilliant source of qualitative data. Watching a session replay feels a
        lot like you’re sitting next to the user. Thanks to them, you can find plenty of areas for
        improvement.
      </p>
      <p>
        <strong>Our choice for remote research: </strong>
        <a href="/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </a>
      </p>
      <a className="action-banner" href={bannerLink.link} target="_blank" rel="noopener noreferrer">
        Test LiveSession now and see how easy it is. Try it for free.
      </a>
      <h3>Note-taking tools </h3>
      <p>
        During the sessions, you’ll be taking a lot of notes. Having them on sheets on paper or
        sticky notes might be a bit troublesome – it’s not hard to lose some of them! Fortunately,
        there are tools to help you organize and then analyze your notes. With those, you can keep
        all of your data in one place and come back to it anytime.
      </p>
      <p>
        <strong>Our choice for remote UX testing sessions:</strong>{' '}
        <a
          href="https://www.optimalworkshop.com/reframer/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Reframer
        </a>
      </p>
      <h3>A/B testing tools</h3>
      <p>
        Last but not least, there’s a{' '}
        <a
          href="https://www.g2.com/categories/a-b-testing"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          choice of tools
        </a>{' '}
        that make setting up A/B experiments as easy as possible. Most of them provide you with a
        ready-to-analyze statistical analysis so you can draw actionable insights right away.
      </p>
      <p>
        <strong>Example:</strong>{' '}
        <a href="https://www.optimizely.com/" target="_blank" rel="noopener noreferrer nofollow">
          Optimizely
        </a>
      </p>
      <h2>What’s next for remote research</h2>
      <p>
        It looks like we won’t be returning to our usual ways anytime soon. Because of this,
        learning how to use remote UX research methods is sure to prove useful. It might seem
        challenging at first to set everything up remotely, especially if you were used to in-person
        meetings. Luckily, there’s a wide range of remote research tools to help you out. Who knows,
        maybe it will turn out that you actually prefer remote research? Why not give it a try?
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: '5 UX Research Methods for the Remote Times',
  url: '/blog/5-ux-research-methods-for-the-remote-times/',
  description: `When social distancing is crucial and avoiding gatherings is recommended, in-person UX research is out of the question. Adapting to the new, remote world is vital. That also means adapting your UX research methods.`,
  author,
  img,
  imgSocial: img,
  date: '2020-08-17',
  category: '',
  group: 'Research',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
